import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable'
import { authRoles } from '../../auth/authRoles'

const Analytics = Loadable(lazy(() => import('./Analytics')))
const EditProduct = Loadable(lazy(() => import('./EditProduct')))

//Posts
// const AddInterest = Loadable(
//     lazy(() => import('../eld/interests/AddInterest/AddInterest'))
// )
// const EditInterest = Loadable(
//     lazy(() => import('../eld/interests/EditInterest/EditInterest'))
// )
const Orders = Loadable(lazy(() => import('../viral/help/Help')))
const ProductTypes = Loadable(lazy(() => import('../viral/currencies/Currencies')))
const Vendors = Loadable(lazy(() => import('../viral/vendors/vendors')))
const ViewOrder = Loadable(lazy(() => import('../viral/layouts/Layouts')))

const dashboardRoutes = [
    {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin,
    },
    {
        path: '/editProduct',
        element: <EditProduct />,
        auth: authRoles.admin,
    },
    {
        path: '/orders',
        element: <Orders />,
        auth: authRoles.admin,
    },
    {
        path: '/vendors',
        element: <Vendors />,
        auth: authRoles.admin,
    },
    {
        path: '/viewOrder',
        element: <ViewOrder />,
        auth: authRoles.admin,
    },
    {
        path: '/productTypes',
        element: <ProductTypes />,
        auth: authRoles.admin,
    },
    // {
    //     path: '/admin/help',
    //     element: <Help />,
    //     auth: authRoles.admin,
    // },
    // {
    //     path: '/admin/layouts',
    //     element: <Layouts />,
    //     auth: authRoles.admin,
    // },
    // {
    //     path: '/admin/reportedPosts',
    //     element: <ReportPosts />,
    //     auth: authRoles.admin,
    // },
]

export default dashboardRoutes
