import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import UserService from 'app/services/UserService'

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
}

const isValidToken = async (accessToken) => {
    if (!accessToken) {
        return false
    }
    const response = await UserService.loginWithToken(accessToken)

    // const decodedToken = jwtDecode(accessToken)
    // const currentTime = Date.now() / 1000
    // return decodedToken.exp > currentTime
    console.log('abcxyz')
    if (response) {
        console.log('OK TRUE')
        return true
    }
    return false
}

const setSession = (accessToken) => {
    console.log('INSIDE SET SESSION', accessToken)
    if (accessToken) {
        console.log('OKKK: ')
        localStorage.setItem('accessToken', accessToken)
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
    } else {
        localStorage.removeItem('accessToken')
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
            }
        }
        case 'LOGIN': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        case 'SETUSER': {
            const { user } = action.payload

            return {
                ...state,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    setActiveUser: () => Promise.resolve(),
    logout: () => {},
    register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (email, password) => {
        // const response = await UserService.loginUser(email, password)
        // console.log('RESPONSE: ', response)
        const response = await fetch('https://api.cactusmoments.com/user/login', { method: 'POST', headers: { 'Content-Type': "application/json", 'Accept': "application/json", "Authorization": accessToken}, body: JSON.stringify({email, password, device: { id: "web", deviceToken: "web" }}) })
        const { data } = await response.json()
        console.log(data)
        const { token, user } = data
        const accessToken = token

        setSession(accessToken)

        dispatch({
            type: 'LOGIN',
            payload: {
                user,
            },
        })
    }

    const register = async (email, username, password) => {
        const response = await axios.post('/api/auth/register', {
            email,
            username,
            password,
        })

        const { token: accessToken, user } = response.data

        setSession(accessToken)

        dispatch({
            type: 'REGISTER',
            payload: {
                user,
            },
        })
    }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
        ;(async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken')

                if (accessToken) {
                    console.log('TRUE HO GAY AHY', accessToken)
                    setSession(accessToken)

                    // const response = await axios.get('/api/auth/profile')
                    // const { user } = response.data
                    // console.log('response.data', response.data)
                    const response = await UserService.loginWithToken(
                        accessToken
                    )
                    const user = response.data
                    console.log('RESPONSE: ', response.data)

                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: true,
                            user,
                        },
                    })
                } else {
                    console.log('FALSE HO GAY AHY')
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    })
                }
            } catch (err) {
                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })()
    }, [])

    if (!state.isInitialised) {
        return <MatxLoading />
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
