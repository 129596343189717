import axios from 'axios'
let axiosInstance = axios.create({
    // baseURL: 'https://api.cactusmoments.com/',

    // baseURL:
    //     process?.env?.NODE_ENV !== 'development'
    //         ? 'https://acesport.co.za/'
    //         : 'http://localhost:4100/',
    // baseURL: 'http://localhost:4200/',
    // baseURL: 'https://allthingslocalapp.herokuapp.com/',
    // baseURL: 'http://localhost:5000/',
    // withCredentials: true,
    baseURL: 'https://api.cactusmoments.com',
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
})
axiosInstance.defaults.headers.common['x-auth-token'] = localStorage.getItem(
    'accessToken'
)
    ? localStorage.getItem('accessToken').toString()
    : ''

// axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
// axiosInstance.interceptors.response.use(
//   response => successHandler(response),
//   error => errorHandler(error)
// );
const errorHandler = (error) => {
    return Promise.reject({ ...error })
}

const successHandler = (response) => {
    return response
}
export default axiosInstance
